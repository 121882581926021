<template>
<!-- <v-col
  cols="12"
  md="6"
  xl="4"
  >
  <v-card
    outlined
    > -->
    <v-expansion-panel>
    <v-expansion-panel-header>{{ ticket.title }}</v-expansion-panel-header>
      <v-expansion-panel-content>
      <template v-if="ticket.media.length">
        <MediaDisplay
          :media="ticket.media"
          />
      </template>
      <template v-if="ticket.text">
        {{ ticket.text }}
      </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import MediaDisplay from '@/components/MediaDisplay'

export default {
  components: {
    MediaDisplay
  },
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  }
}
</script>