<template>
<div>
  <v-carousel
    v-model="slide"
    v-if="media.length > 1"
    :continous="false"
    hide-delimiter-background
    hide-delimiters
    progress-color="green"
    height="550"
    >
    <v-carousel-item
      v-for="(media_item, index) in media"
      :key="`item-${index}`"
      >
        <v-img
          v-if="url[index]"
          :src="url[index]"
          class="align-end text-center"
          :height="media[index].alt ? 450 : 550"
          contain
          >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                size="36"
                indeterminate
                color="primary"
                />
            </v-row>
          </template>
        </v-img>
        <div  
          v-else
          :style="`height: ${media[index].alt ? 450: 550 }px`"
          class="d-flex justify-center align-center"
          > 
              <v-progress-circular
                size="36"
                indeterminate
                color="primary"
                />
          </div>
        <div class="text-body-2 black--text text-center mt-2" v-if="media[index].alt">{{ media[index].alt }}</div>
    </v-carousel-item>
  </v-carousel>
  <v-img
    v-else-if="url[0]"
    :src="url[0]"
    class="align-end text-center"
    max-height="550"
    contain
    >
    <span class="text-body-2" v-if="media[0].alt">{{ media[0].alt }}</span>
  </v-img>
</div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/storage'
export default {
  components: {
    
  },
  props: {
    media: {
      type: Array,
      required: true
    }
  },
  data(){
    return {
      url: [],
      slide: 0,
    }
  },
  watch: {
    // slide: async function(val){
    //   console.log(val);
    //   const urls = this.url
    //   if(!this.url[val]){
    //     const url = await firebase.storage().ref().child(`public/support/${this.media[val].filename}`).getDownloadURL()
    //     urls[val] = url
    //     this.url = urls
    //   }
    // }
  },
  computed: {
    
  },
  methods: {
    
  },
  async mounted(){
    const promises = this.media.map( media_item => 
      firebase.storage().ref().child(`public/support/${media_item.filename}`).getDownloadURL()
      )
    const url = await Promise.all(promises)
    this.url = url
    // const url = await firebase.storage().ref().child(`public/support/${this.media[0].filename}`).getDownloadURL()
    // this.url[0] = url
  }
}
</script>