<template>
 <v-expansion-panel>
    <v-expansion-panel-header>Contact support</v-expansion-panel-header>
    <v-expansion-panel-content>
      <template v-if="!ticket_sent">
        <v-form ref="support_form" @submit.prevent="submit_support_ticket">

          <p class="text-body-2">
          Reach out to us and we will respond by email within a few hours.
        </p>
          <v-text-field
            type="email"
            outlined
            v-model="email"
            :rules="rules.user.email"
            label="E-mail"
          />
          <v-textarea
            outlined
            v-model="question"
            :rules="[v => v.length > 10 || 'Required']"
            rows="3"
            placeholder="Could you look into adding a integration to USPS?"
            />
          <v-btn
            color="primary"
            type="submit"
            >
            Send
          </v-btn>
        </v-form>
      </template>
      <template v-else>
        <p class="text-body-2">
          Thanks for contacting us
        </p>
        <p class="text-body-2">
          We will reach out to you by email typically within a few hours! 
        </p>
      </template>
    </v-expansion-panel-content>
 </v-expansion-panel>
</template>

<script>

import rules from '@/utils/rules'

export default {
  components: {
    
  },
  data(){
    return {
      question: '',
      email: '',
      rules,
      ticket_sent: false,
    }
  },
  computed: {
    
  },
  methods: {
    submit_support_ticket(){
      if(!this.$refs.support_form.validate()) return 
      const { email, question } = this
      this.$store.dispatch('app/submit_support_ticket', { email, question })
      this.ticket_sent = true
    }
  },
  mounted(){
    
  }
}
</script>