<template>
 <v-container
    id="support"
    fluid
    tag="section"
    class="px-md-12"
  >
  <template v-if="!$store.state.user.user">
    <div class="text-center">
      <v-img :src="logo" max-height="50px" contain class="mt-12"/>
      <h5>{{ $appName }}</h5>
      <h6 class="my-4">Support & FAQ</h6>
    </div>
  </template>
  <v-expansion-panels>
  <SupportChat />
  <TicketCard
    v-for="(ticket, index) in tickets"
    :key="`ticket-${index}`" 
    :ticket="ticket"
    />
  </v-expansion-panels>
</v-container>

</template>
<script>
import SupportChat from '@/components/SupportChat'
import TicketCard from '@/components/TicketCard'
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  components: {
    SupportChat,
    TicketCard,
  },
  computed: {

  },
  
  
  filters: {
    
  },
  data() {
    return {
      tickets: [],
      logo: require('@/assets/favicon-192.png'),
    }
  },
  methods: {
  },
  async mounted(){
    const tickets = await firebase.firestore().collection('config').doc('tickets').get().then(res => res.data().tickets)
    this.tickets = tickets
  }
}

</script>
